/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import url("//fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import "variables";
@import "mixins";

body.login {
	background: var(--color6);
	font-family: var(--font-family-primary);
	h1 {
		a {
			width: rem(200);
			height: rem(100);
			background: url(../img/logo.webp) no-repeat center center / contain;
		}
	}
	form {
		border: none;
	}
}

#login {
	background: none;
	box-shadow: none;
}

.login #backtoblog,
.login #nav,
.login .privacy-policy-page-link {
	a {
		color: var(--text-color);
		&:hover {
			color: var(--color1);
			text-decoration: underline;
		}
	}
}

.wp-core-ui,
.language-switcher {
	input.button,
	.button-large,
	.button-primary {
		background: var(--color1);
		color: #FFF;
		border: none;
		border-radius: 2px;
		box-shadow: none;
		text-shadow: none;
		text-transform: none;
		font-weight: 700;
		transition: all 0.2s ease-in-out;
		&:hover,
		&:active,
		&:focus {
			background: var(--color2) !important;
			color: #FFF;
			outline: 0;
			box-shadow: none !important;
		}
	}
}
