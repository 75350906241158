/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

:root {

	// COLOURS
	// ---------------

	--color1:               #67c5ef;
	--color2:               #0E5293;
	--color3:               #AF205A;
	--color4:               #A4DCF5;
	--color5:               #E5F3FD;
	--color6:               #E4F2FD;

	// TYPOGRAPHY
	// ---------------

	--text-color:           #07222f;
	--text-base-size:        16px;
	--base-line-height:      1.5;
	--base-type-spacing:     1rem;

	--font-family-primary:   "Lato", Helvetica, Arial, sans-serif;
	--font-family-secondary: var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--headings-color:        var(--text-color);
	--hr-color:              var(--color4);

}